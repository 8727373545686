<template>
    <ValidationObserver ref="jobOrderDetailsObserver">
        <JobForm>
            <template #actions>
                <div
                    class="d-flex justify-content-between job-order-details-card__footer mt-1"
                >
                    <div>
                        <b-button variant="primary" @click="acceptCurrentJob"
                            >قبول</b-button
                        >
                        <b-button
                            variant="outline-danger"
                            class="mx-2"
                            @click="rejectCurrentJob"
                            >رفض</b-button
                        >
                    </div>
                    <div>
                        <b-button
                            variant="outline-danger"
                            class="mx-2"
                            @click="deleteJobOrder"
                        >
                            حذف
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="navigateToOrders"
                            >تراجع</b-button
                        >
                    </div>
                </div>
            </template>
        </JobForm>
    </ValidationObserver>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import JobForm from "../../jobs/components/JobOrderForm.vue";

export default {
    components: {
        JobForm,
    },
    props: {
        id: String,
        activityId: String,
        userId: String,
    },
    computed: {
        ...mapState({
            jobOrderDto: (state) => state.jobs.jobOrderDto,
        }),
        ...mapGetters(["activitesList"]),
        isActivityOrder() {
            return this.$route.name == "ActivitiyJobOrderDetails";
        },
        isUserOrder() {
            return this.$route.name == "UserJobOrderDetails";
        },
    },
    created() {
        this.fetchJobOrderById(this.id);
    },
    beforeDestroy() {
        this.Reset_Job_Order_Dto();
    },
    methods: {
        ...mapActions([
            "fetchJobOrderById",
            "acceptJobOrder",
            "rejectJobOrder",
            "acceptActivityJobOrder",
            "rejectActivityJobOrder",
            "acceptUserJobOrder",
            "rejectUserJobOrder",
            "deleteRangeJobOrder",
        ]),
        ...mapMutations(["Reset_Job_Order_Dto"]),
        acceptCurrentJob() {
            this.$refs.jobOrderDetailsObserver.validate().then((success) => {
                if (success) {
                    if (this.isActivityOrder) {
                        this.acceptActivityJobOrder({
                            id: this.id,
                            activityId: this.activityId,
                        });
                    } else {
                        this.acceptJobOrder(this.id);
                    }

                    this.$refs.jobOrderDetailsObserver.reset();
                }
            });
        },
        rejectCurrentJob() {
            this.$refs.jobOrderDetailsObserver.validate().then((success) => {
                if (success) {
                    if (this.isActivityOrder) {
                        this.rejectActivityJobOrder({
                            id: this.id,
                            activityId: this.activityId,
                        });
                    } else {
                        this.rejectJobOrder(this.id);
                    }

                    this.$refs.jobOrderDetailsObserver.reset();
                }
            });
        },
        navigateToOrders() {
            if (this.isActivityOrder) {
                this.$router.push(`/admin/activities/${this.activityId}`);
                return;
            } else if (this.isUserOrder) {
                this.$router.push(`/admin/users/details/${this.userId}`);
                return;
            }
            this.$router.push("/admin/orders");
        },
        deleteJobOrder() {
            this.deleteRangeJobOrder([{ id: this.id, requestTypes: 3 }]);
        },
    },
};
</script>
